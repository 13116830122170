import React from 'react'
import { get, capitalize } from 'lodash/fp'

import { Box, Flex, Text, SVG } from 'system'
import { Subheading } from 'src/components/Subheading'
import { IconEmail } from 'src/components/IconEmail'
import { IconFax } from 'src/components/IconFax'
import { IconMarker } from 'src/components/IconMarker'
import { IconPhone } from 'src/components/IconPhone'
import { IconWeb } from 'src/components/IconWeb'

const allIconProps = {
  address: { svg: IconMarker, x: 384, y: 512 },
  email: { svg: IconEmail, x: 512, y: 384 },
  fax: { svg: IconFax, x: 1, y: 1 },
  phone: { svg: IconPhone, x: 1, y: 1 },
  website: { svg: IconWeb, x: 496, y: 512 },
}

export const ContactInfo = ({ type, withIcon = true, children, ...props }) => {
  const iconProps = get(type, allIconProps)

  return (
    <Flex alignItems="flex-start" boxStyle="lastNoMargin" {...props}>
      {withIcon && iconProps && (
        <SVG mt={1} mr={2} width="1.5rem" fill="navy" {...iconProps} />
      )}
      <Box>
        {type && (
          <Subheading as="h5" fontSize="tiny" lineHeight="solid" mb={1}>
            {capitalize(type)}
          </Subheading>
        )}
        <Text>{children}</Text>
      </Box>
    </Flex>
  )
}
