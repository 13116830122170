import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get, map } from 'lodash/fp'

import { Text, GatsbyImage, GatsbyImageContainer, Link } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { ButtonArrow } from 'src/components/ButtonArrow'
import { ContactInfo } from 'src/components/ContactInfo'
import { Heading } from 'src/components/Heading'
import { Layout } from 'src/components/Layout'
import { MarkdownContent } from 'src/components/MarkdownContent'
import { Subheading } from 'src/components/Subheading'
import { TwoColumnContent } from 'src/components/TwoColumnContent'

import { PageTitle } from 'src/slices/PageTitle'

const PhysicianTemplate = ({ data }) => {
  const physician = get('markdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', physician)}</title>
      </Helmet>
      <PageTitle>Physicians</PageTitle>
      <BoundedBox maxWidth="large">
        <TwoColumnContent
          renderLeft={() => (
            <>
              {get('frontmatter.photo.childImageSharp.fluid', physician) && (
                <GatsbyImageContainer
                  borderRadius="50%"
                  maxWidth="18rem"
                  mb={[3, 4]}
                  mx="auto"
                  overflow="hidden"
                >
                  <GatsbyImage
                    fluid={get(
                      'frontmatter.photo.childImageSharp.fluid',
                      physician
                    )}
                    alt={get('frontmatter.title', physician)}
                  />
                </GatsbyImageContainer>
              )}
              {get('frontmatter.phoneNumber', physician) && (
                <ContactInfo type="phone" mb={2}>
                  {get('frontmatter.phoneNumber', physician)}
                </ContactInfo>
              )}
              {get('frontmatter.faxNumber', physician) && (
                <ContactInfo type="fax" mb={2}>
                  {get('frontmatter.faxNumber', physician)}
                </ContactInfo>
              )}
              {get('frontmatter.emailAddress', physician) && (
                <ContactInfo type="email" mb={2}>
                  {get('frontmatter.emailAddress', physician)}
                </ContactInfo>
              )}
              {get('frontmatter.website', physician) && (
                <ContactInfo type="website">
                  <Link to={get('frontmatter.website', physician)}>
                    {get('frontmatter.website', physician)}
                  </Link>
                </ContactInfo>
              )}
              <Subheading as="h4" mt={[4, 6]} mb={2}>
                Office Locations
              </Subheading>
              {map(
                location => (
                  <Text mb={1} boxStyle="lastNoMargin">
                    {get('name', location)}
                  </Text>
                ),
                get('frontmatter.officeLocations', physician)
              )}
            </>
          )}
          renderRight={() => (
            <>
              <Heading as="h3" color="teal" mb={1}>
                {get('frontmatter.title', physician)}
              </Heading>
              <Text
                color="sandDark"
                fontSize="midLarge"
                mb={[2, 4]}
                lineHeight="copy"
              >
                {get('frontmatter.specialties', physician)}
              </Text>
              <MarkdownContent
                markdown={get('rawMarkdownBody', physician)}
                color="navy"
                lineHeight="wide"
                mb={4}
              />
              <ButtonArrow to="/our-team/" direction="left">
                Back
              </ButtonArrow>
            </>
          )}
        />
      </BoundedBox>
    </Layout>
  )
}

export default PhysicianTemplate

export const query = graphql`
  query PhysicianTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        specialties
        photo {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        phoneNumber: phone_number
        faxNumber: fax_number
        emailAddress: email_address
        website
        officeLocations: office_locations {
          name
          phoneNumber: phone_number
        }
        # team {
        #   name
        #   title
        #   photo
        # }
      }
      rawMarkdownBody
    }
  }
`
